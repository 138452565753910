import React from 'react'
import { Link } from 'gatsby'
import pic03 from '../images/coyote_art.jpg'
import pic04 from '../images/cactus_art.jpg'
import placeInRoom from '../images/place_in_room.jpg'
import helperAR from '../images/helper_ar.jpg'


import Layout from '../components/layout'

const SecondPage = () => (
  <Layout style={{ position: "absolute", left: "1em", width: "100%"}}>
    <h1>FQA</h1>
    {/* <p>Welcome to the FAQ</p> */}
    <h2 id="wall">
      Wall placement
    </h2>
    <p>
      We’ve made it happen – Our Augmented Reality (AR) tool can bring your chosen object to life in your own space, using an iOS or Android device, wherever you choose to be!
      You can now use your Apple (iPhone, iPad) or Android mobile device to view and walk around picturs in real time – in any setting you choose.
    </p>

    <div style={{textAlign: "center"}}><img src={pic03} alt="Augmented Reality picture - Coyote" width="250px" /></div>


      <p>
      We’ve been testing this in detail for months and it just works! <br />
      The results are AR objects which are reactive to ambient lighting and will adjust to your own environment the light in any room. 
    
      <div style={{ textAlign: "center" }}><img src={pic04} alt="Augmented Reality picture - Cactus" width="250px" /></div>

      Sometimes new tech can be tricky to use though, which is why we created these instructions to make it as easy as possible for you to enjoy our visualization feature - AR.
      </p>

      <h4>Start</h4>
      <p>
        To start an Augmented Reality session tap the button, that says <i><b>Place in your room</b></i>         and your device will transform into Augmented Reality (AR) mode.
        <div style={{ textAlign: "center" }}><img src={placeInRoom} alt="Place in room - AR" width="150px" /></div>
        <br />
        It will then ask you to move your phone around so that the camera can capture the specific wall on which you’re looking to place your new piece of art. You may get prompts along the way to help.
        <div style={{ textAlign: "center" }}><img src={helperAR} alt="Helper - AR" width="90%" /></div>
      </p>
      <p>Once your device has got a clear view of the room, you’ll find your chosen print placed onto the wall in front of you. You can now move it using finger gestures on the screen.</p>
      <p>Now that you placed the object it will stay there. You can walk around the room to expore you new art piece from every direction. You can also take a picture by taping the circle on the lower part of the screen and share it with your fiends. <br />(Pro-Tip: If you hold the circly you can record a video)</p>
      
      <p>A few more things that might improve your experience: </p>
      <ul>
        <li>
          Hold your iPhone perpendicular to the wall where you want to place the object on before you tap the 'View on your wall' button
        </li>
        <li>
          Ensure that the room where you want to place the object is well lit
        </li>
        <li>
          If the object doesn't stick to the wall the first time, close the AR view with the <b>X</b> and start it again. (Sometimes it just takes a 2nd try)
        </li>
      </ul>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
